.selection-group-wrapper :global {
  //border: 1px solid red;
  text-align: left;
  .filter-item {
    margin-top: 11px;
  }
  .filter-item:first-child {
    margin-top: 0;
  }
}
