@import "Css/Colors.scss";

.zygosity-type-wrapper :global {
  display: flex;
  justify-content: right;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-right: 55px;
  align-items: center;
  padding-top: 14px;
  cursor: pointer;

  .current-zygosity-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding-right: 140px;

    .title {
      font-size: 14px;
      line-height: 131.99%;
      text-align: center;
      color: $title_color;
    }
    .context {
      font-size: 14px;
      font-weight: 600;
      line-height: 131.99%;
      text-align: center;
      color: $brand_primary_color;
      padding-left: 5px;
    }
  }

  .zygosity-type-radio-group {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 8px;
    flex-direction: row;

    .first-button {
      margin-right: 18px;
    }
  }
}
