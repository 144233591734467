@import "Css/Colors.scss";

.number-variants-wrapper :global {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  color: $title_color;
  padding-right: 8px;
  width: 150px;
}
