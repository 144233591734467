@import "Css/Colors.scss";

.header-wrapper :global {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100%;
  background-color: $title_color;
  font-style: normal;
  font-size: 16px;
  text-align: left;

  .left-wrapper {
    margin-left: 2em;

    .go-back-button {
      display: flex;
      width: 110px;
      height: 33px;
      justify-content: center;
      align-items: center;
      background-color: $brand_primary_color;
      border-radius: 5px;
      cursor: pointer;

      .go-back-button-text {
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        color: $white;
      }

      &:hover {
        background-color: $brand_primary_hover_color;
      }
    }
  }

  .right-side-item {
    width: auto;
    margin-right: 20px;
  }
}
