.portal-wrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgba(43, 50, 56, 0.6);
  top: 0;
  left: 0;
  z-index: 10;

  .portal {
    display: block;
    padding: 10px;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
}
