@import "Css/Colors.scss";

.classification-history-wrapper :global {
  width: 100%;
  height: auto;
  border-bottom: 1px solid $border_color;
  overflow: hidden !important;

  .classification-history-title {
    margin-bottom: 12px;
    text-align: left;
    font-weight: normal;
    font-size: 18px;
    color: $title_color;
  }
  .empty-state {
    height: 177px;
  }
  .ant-table-wrapper {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
    width: 100%;

    &.classification-history-table-wrapper {
      .ant-table-content {
        //max-height: 300px !important;
        .ant-table-scroll {
          overflow: hidden !important;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          transition: all 0.4s ease;

          .ant-table-body {
            max-height: 255px !important;
            overflow-y: scroll !important;
            overflow-x: hidden !important;
          }
        }
      }
      &.with-scroll {
        height: 300px;
        max-height: 300px !important;
        box-shadow: inset 0 -91px 40px -110px rgba(0, 0, 0, 0.17);
      }
    }
    .with-scroll {
      overflow-y: scroll !important;
    }
  }
  .label-custom-style {
    padding-left: 12px;
  }
}
