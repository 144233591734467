@import "./Colors";
$sideBarWidth: 197px;
$toolBarAndHeader: 96px;
$heightTableBody: 230px;
$heightTableContent: 150px;
$heightTableBodyWithTumorPanel: 278px;
$heightTableContentWithTumorPanel: 198px;

// button
.ant-btn {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  padding: 5px 34px !important;
  //max-height: 20px!important;
  display: flex;
  align-items: center;
}

.toolbar-btn-wrapper {
  .ant-btn {
    color: $title_color;
    font-size: 14px !important;
    line-height: 18px !important;

    padding: 0 !important;
    width: 100%;
    height: 28px;
    // border-color: $border_color;
    border: none;
    border-radius: 4px;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      color: white;
      background-color: $brand_primary_color;
    }

    &.ant-btn-primary,
    &.ant-btn-primary[disabled] {
      background-color: $brand_primary_color !important;
      color: white !important;
      border: none;
    }
  }
}

.ant-btn-primary {
  background: $brand_primary_color !important;

  &:hover {
    background: $brand_primary_hover_color !important;
  }
}

.ant-btn-default {
  background: $button-disabled !important;
  border-color: $button-disabled !important;
  color: $white !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover {
  color: $white;
  background-color: $button-disabled !important;
}

.ant-radio-inner:after {
  left: 4px;
  top: 4px;
}

.ant-radio-wrapper {
  display: block;
  font-size: 16px !important;
}

// collapse
.ant-collapse {
  background: $white;
  box-sizing: border-box;
  border: none !important;
  width: 168px !important;

  & > .ant-collapse-item {
    border-top: none;
    border-radius: 4px !important;
    //margin-bottom: 16px;
    text-align: left;
    border-bottom: none !important;
  }
  .ant-collapse-item-active {
    border-bottom: none !important;
  }

  .ant-collapse-content-box {
    border: none;
  }

  .ant-collapse-content {
    overflow: initial;
    border-top: none;
    padding-bottom: 14px;
  }

  .ant-collapse-header {
    color: $title_color !important;
    background-color: $white;
    font-size: 14px;
    font-weight: 600;
    //border: 1px solid $border_color;
    border-bottom: none !important;
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    //padding: 0!important;
    line-height: 14px !important;
    padding: 16px 0 16px 0 !important;
    border-top: 1px solid $border_color !important;

    svg {
      width: 12px;
      height: 10px;
      //margin: 0 20px 0 16px;
      fill: $icon_color;
      margin-right: 11px;

      * {
        fill: $icon_color;
      }
    }

    label {
      margin: 0;
    }
  }

  .collapse-childrens {
    width: 153px !important;
    .ant-collapse-header {
      border-top: none !important;
    }
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 15px;
    padding-right: 0;
  }

  .panel-header {
    display: flex;
    justify-content: space-between !important;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
  }

  // radio-button
  .ant-radio-button-wrapper {
    margin-right: 8px;
    border-radius: 4px;
    background: $white;
    padding: 0 20px;
    border: 1px solid $border_color;
    color: $neutral_30;
  }

  .ant-radio-button-wrapper-checked {
    border: 1px solid #0e8ce6;
    color: #0e8ce6;
    box-shadow: none;
  }
}

// ant-select
.ant-select {
  color: $neutral_30;

  .ant-select-selection,
  .ant-select-selection__rendered {
    height: 100%;
    background-color: inherit;

    .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center;
    }
  }

  .ant-select-selection__rendered {
    line-height: 39px;
    color: $title_color;
    padding-right: 25px;
  }

  .ant-select-arrow {
    margin-top: 0px !important;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-top: 0;
    transform: translateY(-50%);

    svg {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) !important;
    }

    &:hover {
      background-color: $select-option-hovered;
    }
  }

  .anticon.anticon-down.ant-select-arrow-icon {
    svg {
      position: absolute;
      right: 2px;
      border-radius: 0.4px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #96a2aa;
    }
  }

  .select-close {
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }

  .ant-select-open .ant-select-arrow-icon svg {
    transform: none;
  }

  .ant-select-selection__clear {
    opacity: 1;
    &,
    &:hover,
    &:active {
      display: none;
    }
  }

  .ant-select-open {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 4px;
    overflow: hidden;
    background-color: white !important;
    min-width: 164px;

    .ant-select-arrow {
      background: $table-header-bg;
    }

    .ant-select-selection {
      background-color: white !important;
    }

    .ant-select-selection__clear {
      display: block;
      & + .ant-select-arrow {
        display: none;
      }
    }
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    color: $neutral_30;
  }
}

// select .mutation-select-wrapper
.mutation-select-wrapper {
  .ant-select {
    width: 92px !important;
  }

  .ant-select-selection--single {
    height: 28px;
  }

  .ant-select-selection__rendered {
    line-height: 26px;
  }

  .ant-select-open .ant-select-arrow-icon svg {
    transform: none;
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    color: $neutral_30;
  }
}

.ant-select-dropdown {
  font-size: 16px !important;
  line-height: 18px !important;
  color: $title_color !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;

  .ant-select-dropdown-menu-item {
    display: flex;
    align-items: center;
  }

  .ant-select-dropdown-menu-item-active {
    background-color: $select-option-selected !important;

    &:hover {
      background-color: $select-option-hovered !important;
    }
  }

  .ant-select-dropdown-menu-item-selected {
    background-color: $select-option-selected !important;
    font-weight: normal !important;
  }
}

// select .table-select-wrapper
.table-select-wrapper {
  height: 100%;
  width: 100%;
  // min-width: 164px;

  .ant-select {
    width: 100% !important;
    height: 100% !important;
    color: $neutral_30;

    background-color: inherit;
  }

  .ant-select-selection,
  .ant-select-selection__rendered {
    height: 100%;
    border: none;
    border-radius: 0px;
    background-color: inherit;

    .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center;
    }
  }

  .ant-select-selection__rendered {
    line-height: 39px;
    color: $title_color;
    padding-right: 25px;
  }

  .ant-select-selection:focus,
  .ant-select-selection:active {
    border: 0 !important;
    box-shadow: none;
  }

  .ant-select-arrow {
    margin-top: 0px !important;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-top: 0;
    transform: translateY(-50%);

    svg {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) !important;
    }

    &:hover {
      background-color: $select-option-hovered;
    }
  }

  .anticon.anticon-down.ant-select-arrow-icon {
    svg {
      position: absolute;
      right: 2px;
      border-radius: 0.4px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #96a2aa;
    }
  }

  .select-close {
    display: block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }

  .ant-select-open .ant-select-arrow-icon svg {
    transform: none;
  }

  .ant-select-selection__clear {
    opacity: 1;
    &,
    &:hover,
    &:active {
      display: none;
    }
  }

  .ant-select-open {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 4px;
    overflow: hidden;
    background-color: white !important;
    min-width: 164px;

    .ant-select-arrow {
      background: $table-header-bg;
    }

    .ant-select-selection {
      background-color: white !important;
    }

    .ant-select-selection__clear {
      display: block;
      & + .ant-select-arrow {
        display: none;
      }
    }
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    color: $neutral_30;
  }
}

// input .table-input-wrapper
.table-input-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;

  .ant-input,
  .ant-input-number {
    &,
    .ant-input-number-input {
      border: 1px solid rgba(0, 0, 0, 0);
      background-color: inherit;
      height: 28px;
      width: 100%;
      font-size: 16px;
      color: $title_color;
    }

    &,
    input {
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $placeholder-color;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $placeholder-color;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $placeholder-color;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $placeholder-color;
      }

      &:hover,
      &:active {
        border-color: $brand_primary_color !important;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

//Tooltip
.ant-tooltip-arrow {
  display: none !important;
}

//Popover
.ant-popover-arrow {
  display: none !important;
}

.ant-checkbox-wrapper {
  font-size: 16px !important;
  line-height: 21px;
  color: $title_color;
  display: flex !important;
  align-items: center;

  .ant-checkbox {
    padding-top: 0;

    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      border: 2px solid $neutral_30;
      box-sizing: border-box;
      border-radius: 4px;
    }

    & + span {
      line-height: 0px;
      padding-left: 10px;
      padding-right: 0;
    }
  }

  .ant-checkbox-inner {
    &::after {
      left: 25%;
    }
  }

  .ant-checkbox-checked {
    &::after {
      display: none;
    }
    .ant-checkbox-inner {
      border: none;
    }
  }
}

.validation-error-checkbox .ant-checkbox .ant-checkbox-inner {
  background: $error-background !important;
  border: 2px solid $error-message !important;
}

.anticon.anticon-down.ant-select-arrow-icon svg {
  border-top: 7px solid #96a2aa;
}

.ethnic-religious-buttons .buttons-group {
  .ant-radio-group {
    flex-wrap: wrap;

    .ant-radio-button-wrapper {
      padding: 0 12px !important;
      text-align: center;
      margin-right: 8px;
      margin-bottom: 8px;
      border: 1px solid $border_color;
      color: $subtitle_color;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;

      &.ant-radio-button-wrapper-checked {
        border: 1px solid #0e8ce6 !important;
        color: #0e8ce6 !important;
        box-shadow: none !important;
      }
    }
  }
}

@keyframes progress {
  0% {
    background-position: -114px 0, 109px -47px, 114px 42px, 0 47px;
  }
  100% {
    background-position: 0 0, 109px -47px, 114px 42px, 0 47px;
  }
}

.swal2-popup .swal2-styled:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal2-popup .swal2-styled.swal2-confirm {
  &.undo-button-class {
    &:hover {
      opacity: 0.9;
    }
    &.progress {
      padding: 0;
      margin: 0;
      width: 114px;
      height: 47px;
      background-size: 100% 5px, 5px 100%, 100% 5px, 5px 100%;
      animation: progress 15s linear forwards;
      background-image: linear-gradient(
        to bottom,
        $button-disabled 99.99%,
        transparent
      ) !important;
      background-repeat: no-repeat;
      background-position: -114px 0, 109px -47px, 114px 42px, 0 47px;
    }
  }
}

.anticon-check {
  svg {
    color: $panel-filled;
  }
}

.anticon-close {
  svg {
    color: $error-message;
  }
}

.ant-popover {
  z-index: 99999 !important;
  min-width: 200px !important;
}

.ant-popover-inner {
  display: flex !important;
  position: absolute !important;
  //top: 0 !important;
  //left: 0 !important;

  //height: auto !important;
  background-color: $white !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;

  &.ant-popover-inner-content {
    padding: 0 !important;
  }
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  display: none !important;
}

th {
  position: relative;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}

// ant-table
.ant-table {
  font-size: 16px !important;

  .ant-table-thead {
    tr {
      background-color: $table-header-bg;
    }

    .ant-table-column-title {
      // white-space: nowrap;
    }

    .ant-table-selection-column {
      position: relative;

      .ant-table-header-column {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .ant-table-tbody {
    background-color: $table-body-bg;

    tr {
      td {
        height: 40px;
        padding: 0;

        &.ant-table-selection-column {
          position: relative;

          & > span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.selection-cell {
          padding: 0;
          position: relative;

          .ant-checkbox-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .ant-table-row {
      &-selected {
        td {
          background-color: $table-selected-row !important;
        }
      }

      td.select {
        padding: 0;
        position: relative;
      }

      td.no-padding {
        padding: 0;
      }

      .cell-padding {
        padding: 0 15px;
      }

      .full-cell {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .bg-white {
        background-color: white;
      }

      &:hover {
        td {
          background-color: $neutral_10;
        }
      }
    }
    .ant-table-row-hover {
      td {
        background-color: $neutral_10 !important;
      }
    }
  }

  .selection-cell {
    position: relative;

    .table-header-selection-chbx {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.partly {
        &::after {
          content: "";
          position: absolute;
          top: 3px;
          left: 4px;
          width: 8px;
          height: 8px;
          background-color: $brand_primary_color;
          border-radius: 2px;
        }
      }
    }
  }

  .table-multiple-row {
    height: 37px;

    .notes-confirmation-wrapper {
      padding: 0 4px;

      & > div {
        padding: 0 11px !important;
        border: 1px solid rgba(0, 0, 0, 0);
        height: 28px;
        border-radius: 4px;

        &:hover,
        &:active {
          border-color: $brand_primary_color !important;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
        }
      }
    }

    &.hovered {
      background-color: $neutral_10;
      .table-input-wrapper {
        .ant-input,
        .ant-input-number {
          background-color: white;
          border: 1px solid $neutral_hover_20;
        }
      }

      .notes-confirmation-wrapper {
        & > div {
          background-color: white;
          border: 1px solid $neutral_hover_20;
        }
      }
    }
    &.toremove {
      background-color: $light-red;
    }

    &:not(:first-child) {
      border-top: 1px solid $neutral_10;
    }
  }

  .table-act-plus {
    width: 35px;
    height: 100%;
    background-image: url("../assets/circle-plus-grey.svg");
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      background-color: $brand_primary_color;
      background-image: url("../assets/circle-plus-white.svg");
    }
  }

  .table-act-remove {
    width: 35px;
    height: 100%;
    background-image: url("../assets/circle-minus-red.svg");
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    transition: all 0.2s;

    &:hover {
      background-color: $remove-btn;
      background-image: url("../assets/circle-minus-white.svg");
    }
  }

  .validation-error {
    background-color: $main-bg-color;
    outline: 1px solid $validation-error;
  }
}
//.ant-popover {
//  z-index: 99999 !important;
//  min-width: 200px !important;
//}

.ant-popover-inner {
  display: flex !important;
  position: absolute !important;
  //top: 0 !important;
  //left: 0 !important;

  //height: auto !important;
  background-color: $white !important;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3) !important;
  border-radius: 4px !important;

  &.ant-popover-inner-content {
    padding: 0 !important;
  }
}

.ant-table-pagination.ant-pagination {
  float: left !important;
  margin: 8px 16px !important;
}

.ant-table-wrapper {
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100% !important;
  }
}

.tumor-panel-opened {
  .ant-table-content {
    max-height: calc(100vh - #{$heightTableContentWithTumorPanel});
    transition: all 0.4s ease;

    .ant-table-scroll {
      height: calc(100vh - #{$heightTableContentWithTumorPanel});
      overflow-x: auto !important;
      transition: all 0.4s ease;

      .ant-table-body {
        height: calc(100vh - #{$heightTableBodyWithTumorPanel}) !important;
        transition: all 0.4s ease;
      }
      table {
        transition: 0.4s ease;
        transform: translate(0px, 0px);
        width: max-content;
      }
    }
    .ant-table-fixed-left {
      height: calc(100vh - 219px);
      transition: all 0.4s ease;
    }
    .ant-table-body-inner {
      height: calc(100vh - #{$heightTableBodyWithTumorPanel}) !important;
    }
  }
}

.ant-table-content {
  transition: all 0.4s ease;
  max-height: calc(100vh - #{$heightTableContent});

  .ant-table-scroll {
    height: calc(100vh - #{$heightTableContent});
    overflow-x: auto !important;

    .ant-table-body {
      height: calc(100vh - #{$heightTableBody}) !important;
      transition: all 0.4s ease;
    }
    table {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
      width: max-content;
    }
  }
  .ant-table-fixed-left {
    height: calc(100vh - 171px);
    transition: all 0.4s ease;
  }

  .ant-table-body-inner {
    height: calc(100vh - #{$heightTableBody}) !important;
  }
  .ant-table-fixed {
  }
}

.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  min-width: 90px;

  &.selection-cell {
    min-width: 50px;
  }

  &.add-cell {
    min-width: 35px;
  }
}

.sidebar-open {
  .ant-table-wrapper,
  .ant-table-wrapper.classification-history-table-wrapper {
    width: calc(100vw - #{$sideBarWidth}) !important;
  }
}
.ant-table colgroup {
  min-width: 30px !important;
}

// ant-slider
.ant-slider {
  margin: 0 !important;

  .ant-slider-rail {
    background-color: $slider-rail;
  }

  .ant-slide2-step {
    height: 3px;
  }
  .ant-slider-track {
    height: 3px;
  }
  .ant-slider-rail {
    height: 3px;
  }

  .ant-slider-handle {
    background-color: white;
    width: 9px;
    height: 9px;
    border: 1px solid $brand_primary_color;
    margin-top: -3px;
    margin-left: -6px;

    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      background-color: rgba(0, 0, 0, 0);
      border: 5px solid rgba($brand_primary_color, 0.3);
      border-radius: 50%;

      display: none;
    }

    &:hover {
      cursor: pointer;

      &::after {
        display: block;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .ant-slider-track {
    background-color: $slider-bg;
  }
  &:hover {
    // cursor: default !important;
    .ant-slider-track {
      background-color: $slider-bg-hover !important;
    }
    .ant-slider-handle {
      border: 1px solid $brand_primary_color !important;
    }
    .ant-slider-rail {
      background-color: $slider-rail-hover !important;
    }
  }
}
.tumor-info-wrapper {
  .ant-input-suffix {
    svg path {
      fill: $subtitle_color !important;
    }
  }
}

// modal
.ant-modal-body {
  .activity-items-table {
    max-height: 248px;
    overflow-y: scroll;
    .table-header {
      position: sticky;
      top: 0;
      .header-cell {
        display: inline-block;
      }
    }
    .cell {
      width: 33.3%;
      padding: 8px;
      text-align: center;
    }
    .header-cell {
      background: #f2f6f8;
      font-weight: 600;
      font-size: 16px;
    }
    .user-name {
      margin-left: 8px;
    }
  }
  .border {
    border: 1px solid #dae1e6;
  }
  .action-item {
    width: 100px;
    overflow-x: hidden;
    margin: 0 auto;
    .title-prev {
      opacity: 0.5;
    }
    .title-curr {
    }
  }
  .prev-note {
    color: #96a2aa;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .curr-note {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
}

//.ant-select-dropdown.ant-select-dropdown--single {
//  width: 130px !important;
//}

.zygosity-type-radio-group {
  .ant-select.ant-select-enabled {
    width: 200px;
    height: 54px;
  }
  .ant-select-selection.ant-select-selection--single {
    height: 100% !important;
    border-radius: 5px !important;

    svg path {
      fill: white;
    }
    .ant-select-selection__rendered {
      width: 100%;
      display: flex;
      align-items: flex-end;
      left: -11px;
      height: 100%;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      color: $white;

      .ant-select-selection-selected-value {
        display: flex !important;
        justify-content: center;
        align-items: baseline;
        margin: 4px auto;
        overflow: hidden;
        white-space: unset;
        text-overflow: unset;
        padding-right: 0;

        span {
          font-size: 20px;
          line-height: 100%;
          color: $white !important;
        }
      }
    }
  }
}

.ant-table-wrapper {
  &.classification-history-table-wrapper {
    width: 100vw;
    height: 182px;
    overflow: hidden !important;

    .ant-table-thead > tr > th {
      padding: 10px 16px;
    }

    .ant-table-content {
      .ant-table-fixed-left {
        height: 100%;
      }

      .ant-table-body-inner {
        height: 100% !important;
      }

      .ant-table-scroll {
        height: 100% !important;
        overflow-x: auto !important;

        table {
          transition: 0.4s ease;
          transform: translate(0px, 0px);
          width: max-content;
        }
      }
    }
  }
}
