@import "Css/Colors.scss";

.variant-table-wrapper :global {
  .activity-icon-wrapper {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 29px;
    padding-top: 3px;

    &:active,
    &:hover {
      background: #0e8ce6;
      border-radius: 4px;
      path {
        fill: #fff;
      }
    }
  }
  .activity-icon-wrapper.disabled {
    cursor: not-allowed;
    svg {
      opacity: 0.5;
    }
  }
  .activity-log-details {
    position: absolute;
    z-index: 9;
    right: 24px;
    top: -8px;
    min-width: 320px;
    max-height: 121px;
    overflow-y: -webkit-paged-y;
    overflow-y: overlay;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background: #fff;
    padding: 0 20px 0 5px;
    .record {
      border-bottom: 1px solid #dae1e6;
      background: #fff;
      svg {
        height: 27px;
      }
      .record__user {
        min-width: 84px;
        border-right: 1px solid $border_color;
      }
      .record_changed-item {
        width: 100px;
        text-align: center;

        border-right: 1px solid $border_color;
        .notes {
          display: block;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          padding: 0 6px;
        }
      }
      .record_time {
        min-width: 160px;
        text-align: center;
        font-size: 14px;
        color: $neutral_30;
      }
    }
    .record__item {
      margin: 10px 0;
      height: 18px;
      .user-name {
        margin-left: 11px;
        margin-right: 11px;
      }
    }
  }
  .sorter {
    padding: 0;
    .ant-table-header-column {
      width: 100%;
    }
  }
  .table-sorter-wrapper {
    &:hover {
      background: #e7e7e7;
    }
    cursor: pointer;
    min-height: 53px;
    svg {
      cursor: pointer;
      opacity: 0.5;
      width: 9px;
      height: 5px;
      &:hover {
        opacity: 1;
      }
    }
    .arrow-active {
      opacity: 1;
    }
    .top-arrow {
      transform: rotate(180deg);
      margin-bottom: 2px;
    }
    .table-sorter-title {
      margin-right: 6px;
    }
  }
  .table-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-select-selection__rendered {
      margin-left: 0 !important;
    }
  }

  .priority {
    padding-right: 7px;
  }

  .ant-table-fixed {
    table-layout: fixed !important;
  }

  .text {
    width: 70%;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $title_color;
    margin-left: 4px;

    &.cut {
      width: 65%;
      max-width: 110px;
    }
  }

  .numeric {
    text-align: right;

    .inner {
      text-align: right;
      justify-content: flex-end;
      padding-right: 15px;
    }
  }
  .hidden-classification {
    //border: 2px dashed mediumvioletred;
    opacity: 0.3;
  }
}
