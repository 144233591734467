@import "Css/Colors.scss";

.evidence-form-wrapper :global {
  width: 100%;
  display: flex;
  justify-content: center;

  .evidence-form {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 400px;
    margin-top: 43px;

    .form-item {
      margin-bottom: 26px;
    }

    .evidence-level-text {
      padding-left: 0;
      font-weight: normal;
      font-size: 16px;
    }

    label {
      display: flex;
      margin-bottom: 11px;
      font-weight: 600;
      font-size: 16px;
      color: $title_color;
    }
    .divider {
      display: flex;
      width: 100%;
      height: 1px;
      margin: 45px 0;
      border: 1px solid $border_color;
    }

    .form-item-source {
      font-size: 16px;
      line-height: 21px;
      height: 41px;
    }

    textarea {
      resize: none !important;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $title_color;

      &.ant-input {
        height: 225px !important;
        max-height: 225px !important;
        overflow-y: scroll !important;
      }
    }

    .ant-btn {
      height: 44px !important;
      & > span {
        width: 100%;
      }
    }
  }
}
