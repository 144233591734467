@import "Css/Colors.scss";

.send-for-confirmation-popup :global {
  width: 1336px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;

  .confirmation-header {
    background-color: $neutral_10;
    padding: 0 25px;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-close {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    padding: 0;
    cursor: pointer;
  }

  .header-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $title_color;
  }

  .confirmation-content {
    height: 343px;
    overflow: auto;
  }

  .confirmation-table {
    padding: 20px 60px;
  }

  .confirmation-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 23px;
    border-top: 1px solid $border_color;
  }

  .confirmation-btns {
    display: flex;
    margin-left: auto;
  }

  .confirmation-btn {
    width: 120px;
    height: 44px;
    padding: 0 !important;
    border-radius: 4px;
    border: none;
    font-size: 16px !important;
    font-weight: normal !important;
    line-height: 21px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 5px;

    &:not(.confirmation--link) {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      color: white;
      font-weight: 600 !important;
      background-color: $brand_primary_color;
    }

    &[disabled] {
      background-color: $button-disabled !important;
      color: white !important;
    }
  }
}
