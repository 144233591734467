@import "Css/Colors.scss";

.uncheck-confirmation-popup :global {
  width: 400px;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;

  .confirmation-header {
    height: 53px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border_color;
  }

  .header-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: $title_color;
    display: flex;
    align-items: center;
  }

  .warning-sign {
    background-position: center;
    background-repeat: no-repeat;
    width: 22px;
    height: 19px;
    display: inline-block;
    margin-right: 13px;
  }

  .confirmation-content {
    font-size: 14px;
    line-height: 18px;
    color: $title_color;
    padding: 17px 0;
  }

  .confirmation-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .confirmation-btns {
    display: flex;
    margin-left: auto;
  }

  .confirmation-btn {
    width: 64px;
    height: 25px;
    padding: 0 !important;
    border-radius: 4px;
    border: none;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 18px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 5px;

    &:not(.confirmation--link) {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      color: white;
      background-color: $brand_primary_color;
    }
  }
}
