@import "Css/Colors.scss";

.tests-page-wrapper :global {
  display: flex;
  justify-content: flex-start;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 48px);
  overflow: auto;

  .page-content {
    border: 3px dashed red;
  }
  .main-content-wrapper {
    .test-wrapper {
      margin-top: 10px;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
      .text-content {
        text-align: left;
        padding: 33px 60px;
        min-width: 347px;
        .title {
          font-size: 12px;
          font-weight: bold;
          color: $subtitle_color;
        }
        .content {
          font-size: 14px;
          color: #000;
          margin-top: 5px;
        }
      }
    }

    background-color: $main-bg-color;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    z-index: 0;
    transition: 0.4s ease;
    transform: translate(-155px, 0px);

    &.sidebar-open {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
    }
  }

  .sidebar-wrapper {
    display: flex;
    width: 192px;
    height: 100%;
    z-index: 2;
    transition: 0.4s ease;
    transform: translate(-155px, 0px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &.sidebar-open {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
    }
  }
}
