@import "Css/Colors.scss";

.tab-pane-content-wrapper :global {
  width: 100%;
  height: auto;
  .title {
    font-weight: bold;
    font-size: 14px;
    color: $title_color;
    margin-right: 4px;
  }

  .amount {
    font-weight: bold;
    font-size: 14px;
    color: $brand_primary_color;
  }
}
