body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Source Sans Pro, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: Source Sans Pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

button,
button:active,
button:focus {
  outline: none !important;
}

/* div box */
.scrollbar {
  //min-width: 150px;
  overflow-y: scroll;

  &.sidebar-closed {
    overflow-y: hidden;
  }
}
