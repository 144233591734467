@import "Css/Colors.scss";

.highlighted-cell :global {
  height: 100%;
  width: 100%;

  .inner {
    height: 100%;
    width: 100%;
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;

    &.highlighted {
      background-color: $table-highlighted-row;
    }
  }
}
