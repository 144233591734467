@import "Css/Colors.scss";

.tumor-input-number-wrapper :global {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  font-style: normal;
  font-size: 14px;
  padding: 9px;
  margin-left: 35px;
  border-bottom: $border_color solid 1px;

  .label {
    font-weight: normal;
    font-size: 16px;
    color: $subtitle_color;
    margin-right: 4px;
    text-align: right;
  }

  .static-text {
    width: 3em;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $title_color;
    cursor: pointer;

    &:hover {
      color: $slider-bg;
    }
  }

  .placeholder {
    //position: relative;
    z-index: 100000;
    color: $subtitle_color;
  }

  .save-icon {
    margin-left: 11px;
    cursor: pointer;

    &:hover {
      path {
        fill: $neutral_hover_30;
      }
    }
  }
}
