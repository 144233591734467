.filter-item-wrapper :global {
  .checkbox-inner-content {
    padding-top: 2px;
  }
  .ant-radio-wrapper-checked {
    .item-label {
      font-weight: bold;
    }
  }
  .ant-radio {
    display: none;
  }
  .anticon {
    margin-right: 11px;
  }
}
