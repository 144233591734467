@import "Css/Colors.scss";

.filter-chip-indicators :global {
  .indicators-title {
    font-size: 14px;
    line-height: 18px;
    color: $title_color;
  }

  .indicators-items {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }
}
