@import "Css/Colors.scss";

.notes-wrapper :global {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // width: 500px;
  height: 100%;
  min-width: 250px;
  padding: 9px;
  font-weight: normal;
  font-size: 16px;
  color: $neutral_30;
  cursor: pointer;

  .notes-content-empty {
    width: 100%;
    height: 100%;
  }

  .notes-content {
    width: 80%;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $title_color;
  }

  .notes-icon {
    line-height: 4px;
  }

  .edit-text-box {
    display: flex;
    position: absolute;
    top: 0;
    left: -16px;
    min-width: 250px;
    width: calc(100% + 32px);
    height: auto;
    background-color: $white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    z-index: 1;
  }

  .ant-form-explain {
    padding-left: 8px;
  }
}
