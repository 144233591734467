.confirmation-status :global {
  position: relative;
  width: 100%;
  height: 100%;

  .panel-trigger {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .confirmation-icon {
    width: 17px;
    height: 17px;
    min-width: 17px;
    min-height: 17px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .confirmation-arrow-down {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 3px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .confirmation-config-panel {
    position: fixed;
  }
}
