@import "Css/Colors.scss";

.user-wrapper :global {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: 32px;
  height: 32px;

  .avatar-fallback {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    background-color: $brand_primary_color;
    border-radius: 50%;
  }

  .avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}
