//flex-box styles
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: row;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}

.flex-none {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.content-start {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.content-end {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.content-center {
  -ms-flex-line-pack: center;
  align-content: center;
}

.content-between {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.content-around {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.order-last {
  -webkit-box-ordinal-group: 100000;
  -ms-flex-order: 99999;
  order: 99999;
}

//items vertical align
.items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}

.items-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
}

.items-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -ms-grid-row-align: baseline;
  align-items: baseline;
}

.items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
}
