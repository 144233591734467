@import "Css/Colors.scss";

.select-non-active-wrapper :global {
  display: flex;
  position: relative;
  .select-non-active {
    width: 200px;
    height: 54px;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: $brand_primary_color 1px solid;
    border-radius: 5px;
    padding-right: 30px;

    &.active {
      background-color: $brand_primary_color;
    }

    .select-non-active-title {
      margin-top: 2px;
      font-weight: 600;
      font-size: 22px;
      line-height: 100%;
      text-align: center;
      color: $neutral_30;

      &.active {
        color: $white;
      }
    }

    .select-non-active-class {
      display: flex;
      justify-content: center;
      align-items: baseline;
      height: 100%;

      .label-custom-style {
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: $neutral_30;

        &.active {
          color: $white;
        }
      }
      span {
        font-weight: 600;
        font-size: 22px;
        line-height: 100%;
        color: $neutral_30;

        &.active {
          color: $white;
        }
      }
    }
  }
}
