@import "Css/Colors.scss";

.action-delete-evidence-wrapper :global {
  width: 610px;
  height: 350px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;

  .header {
    background-color: $neutral_10;
    padding: 0 25px;
    height: 64px;
    display: flex;
    justify-content: left;
    align-items: center;

    .header-title {
      font-weight: 600;
      font-size: 22px;
      color: $title_color;
      display: flex;
      align-items: center;
    }

    .delete-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px 3px 0;

      svg {
        width: 20px;
        height: 20px;
        g {
          opacity: 1;
          path {
            fill: $error-message;
          }
        }
      }
    }
  }

  .body-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 285px;
    padding: 24px 47px 32px 36px;

    .body-content {
      width: 100%;
      height: 100%;
      font-weight: normal;
      font-size: 22px;
      line-height: 30px;
      color: $title_color;

      & span {
        font-weight: bold;
      }
    }
  }

  .content-footer {
    .buttons {
      display: flex;
      justify-content: flex-end;

      button {
        display: flex;
        justify-content: center;
        width: 84px;
        height: 33px;
        border: none;
        box-shadow: none !important;

        .buttons-text {
          font-weight: normal;
          font-size: 18px;
          text-align: center;
          color: $title_color;
        }
        &:hover {
          background-color: $error-message;
          .buttons-text {
            color: $white;
          }
        }
      }
    }
  }
}
