@import "./Colors.scss";
@import "./AntdOverrides";
@import "./FlexboxStyles";
@import "./AntdOverrides";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");

.App {
  text-align: center;
  display: grid;
  overflow: hidden;
}

.App-header {
  position: relative;
  top: 0;
  z-index: 1;
  background: $white;
  border-bottom: 1px solid $border_color;
  height: 48px;
  color: white;

  .App-header-menu-button {
    cursor: pointer;
    text-align: right;
    margin-right: 24px;
  }

  .ant-select.ant-select-enabled {
    width: 95px !important;
  }
}

.App-link {
  color: #61dafb;
}
