$border_color: #dae1e6;
$icon_color: #424d55;
$title_color: #424d55;
$input-border-color: #424d55;
$subtitle_color: #96a2aa;
$brand_primary_color: #0e8ce6;
$brand_primary_hover_color: #1378c0;
$neutral_30: #96a2aa;
$neutral_hover_30: #778690;
$neutral_hover_20: #c8d1d8;
$neutral_10: #e7eef2;
$white: #fff;
$warning-sign: #ff835c;
$error-message: #f73f3f;
$error-message-hover: #ca1717;
$error-background: #fff2f3;
$remove-btn: #f11e2c;
$validation-error: #f11e2c;
$light-red: #fff3f4;
$button-disabled: #b7ddf7;
$list-single_hover: #f2f6f8;
$filter-list-item: #f2f6f8;
$table-header-bg: #f2f6f8;
$table-body-bg: $white;
$table-selected-row: #f4fcff;
$panel-filled: #22b36e;
$textarea-background: #e6f8ff;
$select-option-selected: #f2f6f8;
$select-option-hovered: #e7eef2;
$slider-bg: #42adfa;
$slider-bg-hover: $brand_primary_color;
$slider-rail: #dae1e6;
$slider-rail-hover: #c8d1d8;
$orange: #ff835c;
$main-bg-color: #f2f6f8;
$adtnl-row-hovered: #ecf7ff;
$placeholder-color: $subtitle_color;
$table-highlighted-row: #e1f7ff;
