@import "Css/Colors.scss";

.simple-button-wrapper :global {
  width: 150px;
  height: 33px;

  .ant-btn {
    width: 100%;
    height: 100%;
    background-color: $brand_primary_color;

    &:hover {
      background-color: $brand_primary_hover_color;
    }
  }

  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    background-color: $brand_primary_color !important;

    &:hover {
      background-color: $brand_primary_hover_color !important;
    }
  }
}
