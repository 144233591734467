@import "Css/Colors.scss";

.tumor-info-select-wrapper :global {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  font-style: normal;
  font-size: 14px;
  padding: 9px;
  border-bottom: $border_color solid 1px;

  .label {
    font-weight: normal;
    font-size: 16px;
    color: $subtitle_color;
    margin-right: 4px;
    min-width: 6em;
    text-align: right;
  }

  .static-text {
    width: 253px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $title_color;
    cursor: pointer;

    &:hover {
      color: $slider-bg;
    }
  }
  .tumor-info-select {
    width: 253px !important;
    height: 24px !important;
    line-height: normal !important;
    background-color: $white !important;
    margin-top: 2px;

    .clear-info-button {
      position: relative;
      right: 30px;
      z-index: 2;
      width: 16px;
      height: 16px;
      background-color: rgba(0, 0, 0, 0);
      background-position: center;
      background-size: contain;
      cursor: pointer;
      border: 0;
      margin-left: 10px;
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;

      &:active,
      &:focus {
        outline: none;
      }
      &::-moz-focus-inner {
        border: 0;
      }
    }
  }
  .placeholder {
    //position: relative;
    z-index: 100000;
    color: $subtitle_color;
  }
  .anticon-close svg {
    color: $subtitle_color;
  }
  .ant-select {
    width: 100% !important;
  }
  .ant-input {
    min-width: 253px !important;
    height: 24px !important;
  }
  .ant-select-selection {
    background: none;
  }
  .save-icon {
    margin-left: 11px;
    cursor: pointer;

    &:hover {
      path {
        fill: $neutral_hover_30;
      }
    }
  }
}
