@import "Css/Colors.scss";

.variant-page-wrapper :global {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  z-index: 0;
  width: 100%;
  height: 100vh;

  .links-wrapper {
    display: flex;
    height: 100%;
    z-index: 2;
    transition: 0.4s ease;
    transform: translate(-155px, 0px);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    &.links-wrapper-open {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
    }
  }

  .main-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100vw;
    height: 100%;
    z-index: 0;
    transition: 0.4s ease;
    transform: translate(-155px, 0px);
    margin-right: 20px;

    &.links-wrapper-open {
      transition: 0.4s ease;
      transform: translate(0px, 0px);
    }
    .main-header-data {
      display: flex;
      height: 120px;
      background-color: $white;
      box-shadow: 0 2px 3px 1px $border_color;
    }

    .main-data {
      display: flex;
      height: calc(100vh - 170px);
      justify-content: space-between;
      flex-direction: column;

      .history {
        display: flex;
        width: calc(100vw - 58px);
        padding: 15px 15px 15px 40px;
        max-height: 455px;
        box-sizing: border-box;

        &.links-wrapper-open {
          width: calc(100vw - 218px);
          transition: 0.4s ease-in;
        }
      }
      .evidence {
        display: flex;
        width: calc(100vw - 40px);
        padding: 15px 15px 15px 40px;
        max-height: 455px;
        overflow: hidden;
        box-sizing: border-box;
        transition: 0.3s ease-in;

        &.links-wrapper-open {
          width: calc(100vw - 203px);
          transition: 0.4s ease-in;
        }
      }
    }
  }
}
