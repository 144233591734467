@import "Css/Colors.scss";

.header-icon-wrapper :global {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  .icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.info {
      &:hover {
        svg path {
          fill: $panel-filled;
        }
      }
    }

    &.notification {
      &:hover {
        svg path {
          fill: $orange;
        }
      }
    }
  }
  .icon-active {
    @extend .icon;
    &.info {
      svg path {
        fill: $panel-filled;
      }
    }
    &.notification {
      svg path {
        fill: $orange;
      }
    }
  }
}
